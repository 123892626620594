<template>
  <router-link :to="'/subscriptions/' + subscription.id" class="block md:flex w-full py-4 md:px-4 border-t border-gray-200 hover:bg-gray-100">
    <div class="font-bold mr-2 md:w-3/6 align-middle" v-if="subscription.products.length">
      #{{ subscription.order_id }}:
      <span v-if="subscription.products[0].pivot.amount > 1">{{ subscription.products[0].pivot.amount }}x</span>
      {{ subscription.products[0].name }}
      <span v-if="hasMultipleNonAddOnProduct()">+ {{ $t('subscriptions.others')}}</span>
    </div>
    <div class="font-bold mr-2 md:w-3/6 align-middle" v-else>
      #{{ subscription.order_id }}
    </div>
    <div class="italic md:w-1/6 align-middle">{{ $t('invoices.subscriptions-from') }} {{ subscription.created_at | formatDate }}</div>
    <div class="text-lg md:text-xl font-bold md:w-2/6 align-middle text-right" :class="{
        'text-red-500': subscription.status === 'paused',
        'text-yellow-600': ['new', 'incomplete', 'unverified'].includes(subscription.status),
        'text-green-500': subscription.status === 'active',
        'text-gray-500': subscription.status === 'cancelled',
      }">
      {{ $t('subscriptions.status.' + subscription.status) }}
    </div>
  </router-link>
</template>

<script>
export default {
  props: {
    subscription: {required: true}
  },
  methods: {
    hasMultipleNonAddOnProduct() {
      return this.subscription.products.filter(product => product.type !== 'kvm_dedicated').length > 1;
    }
  }
}
</script>

<style scoped>

</style>